<template>
  <div>
    <v-data-table
      :headers="Headers"
      :items="siwes"
      class="elevation-1 tb px-2"
      :search="search"
      show-expand
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>IT/Siwes Informations </b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.createdAt="{ value }">
        {{ value | formatDate }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="my-6">
            <v-row no-gutters>
              <v-col cols="auto" class="my-0 py-0 mx-2">
                <v-text-field
                  v-model="item.fee"
                  label="Fee"
                  @change="saveChanges(item, 'fee')"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="my-0 py-0 mx-2">
                <v-text-field
                  v-model="item.payment"
                  label="Payment"
                  @change="saveChanges(item, 'payment')"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="my-0 py-0 mx-2">
                <v-textarea
                  v-model="item.remark"
                  label="Remark"
                  dense
                  auto-grow
                  rows="1"
                  @change="saveChanges(item, 'remark')"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </td>
      </template>
      <template #item.actions="{ item }">
        <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "SiwesInfo",
  mixins: [snackMixin],

  data() {
    return {
      search: "",
      Headers: [
        { text: "Fullname", value: "fullname" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "address", value: "address" },
        { text: "Date of Birth", value: "dob" },
        { text: "Religion", value: "religion" },
        { text: "Gender", value: "gender" },
        { text: "Institution", value: "school" },
        { text: "Degree", value: "degree" },
        { text: "Course of study", value: "discipline" },
        { text: "Created Date", value: "createdAt" },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ],
      siwes: [],
    };
  },

  created() {
    apiClient
      .get("/it-students?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.siwes = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    del(item) {
      const ok = confirm(`Are sure you want to delete this ${item.fullname}?`);
      if (ok) {
        this.displayMsg(`Deleting ${item.fullname}, please wait...`);
        apiClient
          .delete(`/it-students?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.fullname} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.siwes = this.siwes.filter((pt) => pt.id !== item.id);
              this.displayMsg(`${item.fullname} successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg(err.message, "error"));
      }
    },
    async saveChanges(item, type) {
      this.loading = true;
      item.type = type;
      try {
        const res = await apiClient.put("/it-students", item);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err.message, "error");
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
